/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "do-you-really-need-two-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#do-you-really-need-two-hearing-aids",
    "aria-label": "do you really need two hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Do you REALLY need two hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/twoears-hearing-aids.png",
    alt: "man holding two ears hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Why would I need two hearing aids when I only have one bad ear?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sound familiar? You’re not alone. That’s one of the most common questions people ask when trying to understand and treat their hearing loss. And, if you don’t yet know a lot about hearing loss, wearing one hearing aid would definitely seem like the most affordable, common-sense way to go. Because why try to fix something that isn’t broken, right?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But many people don’t realize they actually have bilateral hearing loss — hearing loss in both ears — and most likely it’s just more noticeable on one side. Just because you think one ear is better than the other, doesn’t mean that it’s in great shape. It’s important to get a professional ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " to show the degree of hearing loss in both ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Years of medical research and user experience actually show that two hearing aids are almost always the recommended course of action when it comes to treating bilateral hearing loss. Here’s why."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "you-have-two-ears-for-a-reason",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#you-have-two-ears-for-a-reason",
    "aria-label": "you have two ears for a reason permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "You Have Two Ears for a Reason"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With normal hearing in both ears, you have what is known as binaural hearing. This means your ears work together to equally send signals to the auditory center of your brain to process sound. Two ears are necessary to detect the direction in which sounds are coming from and to experience natural 360-degree hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you have hearing loss — and if you’re wearing just one hearing aid — your brain has a harder time picking up the sound signals and levels equally from each ear. It then becomes more difficult to adequately choose which sounds to focus on and where they’re coming from. And when your mind is struggling to make sense of sound, it can be mentally and physically exhausting and ultimately affect your well-being."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wearing two modern hearing aids enhances the binaural hearing process because they communicate with each other to transfer information back and forth, rather than working independently to process sound. Again, this helps your brain process sound signals and location evenly. Your hearing aids will ensure each ear picks up sound and gets the stimulus it needs to stay at peak performance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two hearing aids help your brain efficiently and effectively do several things:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Determine the location of where sounds are coming from\nIn the example below, when your phone rings, the sound reaches your left ear first. It reaches your right ear slightly later and a sound shadow occurs because your face blocks some of the sound. These subtle differences in how sound reaches both ears are what help you locate sounds.\n", React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/sound-shadow.png",
    alt: "sound shadow created by obstacle",
    className: "c-md-img"
  })), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Decipher between background noise, speech, and other crucial sounds, to help you focus on what’s important"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Provide you with a balanced, natural sound experience"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So if you have one “bad” ear, and aren’t wearing two hearing aids, you won’t be able to experience these important, natural elements of sound."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "improved-speech-clarity--understanding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improved-speech-clarity--understanding",
    "aria-label": "improved speech clarity  understanding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improved speech clarity & understanding"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A study published in the Journal of the American Academy of Audiology looked at the word recognition abilities of people fitted with one hearing aid versus two. The researchers found that a significantly higher number of study subjects wearing only one hearing aid experienced a decline in their word recognition compared to those who had two hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "youll-be-turning-down-the-volume--finally",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#youll-be-turning-down-the-volume--finally",
    "aria-label": "youll be turning down the volume  finally permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "You’ll be turning down the volume — finally"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most-enjoyed perks of wearing two hearing aids is finally being able to turn the volume down instead of up! When softer signals are transferred evenly from both ears, the sound will be louder to the brain."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "but-dont-two-hearing-aids-cost-more",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#but-dont-two-hearing-aids-cost-more",
    "aria-label": "but dont two hearing aids cost more permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "But don’t two hearing aids cost more?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your budget is important. And you shouldn’t have to settle for less than great hearing because of cost. At hear.com, we tell all of our customers that it’s better to invest in two hearing aids with standard technology, as opposed to one expensive hearing aid with premium technology. Because once again, two hearing aids will communicate with each other to help your brain effectively process sound. You also won’t get the most out of a single premium level hearing aid, since certain functionalities are based on wearing two."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s important to talk to your hearing care expert on the best course of action to address your hearing loss. We’re always here as your guide and to answer any questions you may have along the way. If you haven’t already signed up for our 45-day trial, click the button below to experience it for yourself."), "\n", React.createElement(LandingPageCta, {
    copy: "Start your 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
